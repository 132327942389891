;(function (angular) {

  'use strict';

  /* jshint eqeqeq:false, eqnull:true, evil:true */

  function Conditions(fieldDefinitionService, objectUtils) {

    this.lib = this;
    this.getModel = angular.noop;
    this.conditions = {};
    this.fieldDefinitionService = fieldDefinitionService;
    this.objectUtils = objectUtils;

    this.$eval = function (fn) {
      if (this.isString(fn)) {
        var lib = this;
        return eval(fn);
      }
      return fn.apply(this, arguments);
    };
    this.SCOPE_PROPERTY_NAME = 'lib';
  }

  var arr = [];

  Conditions.prototype = {

    constructor: Conditions,

    clone: function (options) {
      if (!this.isObject(options)) {
        options = {};
      }
      var C = new Conditions(this.fieldDefinitionService, this.objectUtils);
      var self = this;
      C.setConditions(options.conditions || self.conditions);
      return C;
    },

    setModelGetter: function (fn) {
      this.getModel = fn.bind(this);
      return this;
    },

    setConditions: function (conditions) {
      this.conditions = conditions;
      return this;
    },

    isString: function (val) {
      return 'string' === typeof val;
    },

    isArray: function (val) {
      return Array.isArray(val);
    },

    isObject: function (val) {
      return !this.isArray(val) && angular.isObject(val) && val !== null;
    },

    isNumber: function (val) {
      return angular.isNumber(val);
    },

    isFunction: function (val) {
      return angular.isFunction(val);
    },

    visibleAndIcontains: function (key, val) {
      return this.toVal(key) && this.contains(key, val, 'gi');
    },

    visibleAndInList: function (key, allowedValues) {
      if (this.toVal(key) && angular.isArray(allowedValues)) {
        var fieldValue = this.getModelValue(key);
        return allowedValues.indexOf(fieldValue) > -1;
      }
    },

    visibleAndNotInList: function (key, allowedValues) {
      if (this.toVal(key) && angular.isArray(allowedValues)) {
        var fieldValue = this.getModelValue(key);
        return fieldValue && allowedValues.indexOf(fieldValue) === -1;
      }
    },

    contains: function (key, argument, flags) {
      var f = 'g';
      if (this.isString(flags)) {
        f = flags;
      }
      if (!this.isString(key) && !this.isNumber(key)) {
        return false;
      }
      if (!this.isString(argument) && !this.isNumber(argument)) {
        return false;
      }
      var exp = new RegExp(argument, f);
      var val = this.getModelValue(key);
      return this.isString(val) && !!val.match(exp);
    },

    gtOrEq: function (arg1, arg2) {
      return this.getModelValue(arg1) >= arg2;
    },

    getModelVisible: function (key) {

      return this.toVal(key) ? this.getModelValue(key) : "";
    },
    visibleAndGtOrEq: function (arg1, arg2) {
      return this.toVal(arg1) && this.gtOrEq(arg1, arg2);
    },
    visibleAndArrayContainsElement: function (arrayKey, element) {
      return this.getModelVisible(arrayKey) ? this.arrayContainsElement(arrayKey, element) : -1;
    },

    arrayContainsElement: function (arrayKey, element) {
      var array = this.getModelValue(arrayKey) ? this.getModelValue(arrayKey) : "";
      return array.indexOf(element);
    },

    /**
     * Tests whether a field matches one of a list of values
     * @param key
     * @param arrayOfValues
     * @returns {boolean}
     */
    isAnyOf: function (key, arrayOfValues) {
      var fieldValue = this.getModelValue(key);
      return arrayOfValues.indexOf(fieldValue) !== -1;
    },

    getNestedValue: function (obj, keys) {
      var self = this;
      if (self.isArray(keys)) {
        return keys.reduce(function (o, k) {
          if (self.isObject(o)) {
            return o[k];
          }
          if (self.isArray(o) && self.isNumber(k)) {
            return o[k];
          }
          return undefined;
        }, obj);
      }
    },

    /**
     * Retrieve a value from the model
     * @param key a simple key, e.g. 'foo' or a property path, e.g. 'foo.bar.baz'
     * @returns {*}
     */
    getModelValue: function (key) {
      return this.objectUtils.getPropertyPath(this.getModel(), key);
    },

    /**
     * Evaluates the condition on a field, i.e. indicates whether a field is visible
     * @param key
     */
    toVal: function (key) {
      var expr = this.toString(key);
      return this.$eval(expr);
    },

    toString: function (key) {

      arr.push(key);

      var self = this;
      var cond;

      if (self.isString(key)) {
        key = [key];
      }

      if (self.isArray(key)) {
        cond = self.getNestedValue(this.conditions, key);
      }

      if (self.isString(cond)) {
        return cond;
      }

      if (self.isFunction(cond)) {
        var path = key.reduce(function (val, k) {
          return val += "['" + k + "']";
        }, '');

        return 'lib.$eval(lib.conditions' + path + ')';
      }

      if (self.isArray(cond)) {
        var mergedConditions = cond.map(function (c, idx) {
          var newPath = key.slice();
          newPath.push(idx);

          // arrays contain string to conditions of functions
          var conditionInPath = self.getNestedValue(self.conditions, newPath);
          if (self.isString(conditionInPath)) {
            return self.toString(conditionInPath);
          }

          return self.toString(newPath);
        }).join(' && ');

        return mergedConditions;
      }
      return 'true';
    }

  };

  angular.module('kerp-forms.forms').service('FormConditions', ['fieldDefinitionService', 'objectUtils', Conditions]);

}(angular));
